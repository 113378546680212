import { ROUTER_URL } from "../constants/urls";

const routes = [
  {
    path: ROUTER_URL.root.link,
    name: ROUTER_URL.root.name,
    component: () =>
      import(
        /* webpackChunkName: "LandingPageLayout" */ "../layouts/BlankLayout/BlankLayout.vue"
      ),
    meta: {
      name: ROUTER_URL.root.name,
      isPublic: true,
      title: ROUTER_URL.root.title,
      requiresAuth: false,
    },
    children: [
      {
        path: ROUTER_URL.root.children.landingPage.link,
        name: ROUTER_URL.root.children.landingPage.name,
        component: () =>
          import(
            /* webpackChunkName: "LandingPage" */ "../views/LandingPage.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.landingPage.title,
        },
      },
      {
        path: ROUTER_URL.root.children.addNewSchool.link,
        name: ROUTER_URL.root.children.addNewSchool.name,
        component: () =>
      
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/SchoolManagement/AddSchoolFormat/AddSchoolFormat.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.addNewSchool.title,
        },
      },
      {
        path: ROUTER_URL.root.children.mediaCredentials.link,
        name: ROUTER_URL.root.children.mediaCredentials.name,
        component: () =>
      
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/SchoolManagement/AddSchoolFormat/MediaCredentials.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.mediaCredentials.title,
        },
      },
      {
        path: ROUTER_URL.root.children.wizfitchallenge.link,
        name: ROUTER_URL.root.children.wizfitchallenge.name,
        component: () =>
      
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/SchoolManagement/AddSchoolFormat/FindMySchool.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.wizfitchallenge.title,
        },
      },
      {
        path: ROUTER_URL.root.children.districtPublicpage.link,
        name: ROUTER_URL.root.children.districtPublicpage.name,
        component: () =>
      
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/SchoolManagement/AddSchoolFormat/DistrictPublicPage.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.districtPublicpage.title,
        },
      },
      {
        path: ROUTER_URL.root.children.emailConfirmation.link,
        name: ROUTER_URL.root.children.emailConfirmation.name,
        component: () =>
      
          import(
              /* webpackChunkName: "StudentManagement" */ "../views/NeedHelp/EmailConfirmation.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.emailConfirmation.title,
        },
      },
      {
        path: ROUTER_URL.root.children.smsConfirmation.link,
        name: ROUTER_URL.root.children.smsConfirmation.name,
        component: () =>
      
          import(
              /* webpackChunkName: "StudentManagement" */ "../views/NeedHelp/SMSConfirmation.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.smsConfirmation.title,
        },
      },
      {
        path: ROUTER_URL.root.children.privacypolicy.link,
        name: ROUTER_URL.root.children.privacypolicy.name,
        component: () =>
      
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/SchoolManagement/AddSchoolFormat/PrivacyPolicy.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.privacypolicy.title,
        },
      },
      {
        path: ROUTER_URL.root.children.termsCondition.link,
        name: ROUTER_URL.root.children.termsCondition.name,
        component: () =>
      
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/SchoolManagement/AddSchoolFormat/TermsCondition.vue"
          ),
        meta: {
          isPublic: true,
          layout: "LandingPageLayout",
          title: ROUTER_URL.root.children.termsCondition.title,
        },
      },
    ],
  },
  {
    path: ROUTER_URL.auth.link,
    name: ROUTER_URL.auth.name,
    component: () =>
      import(
        /* webpackChunkName: "AuthLayout" */ "../layouts/Auth/AuthLayout.vue"
      ),
    meta: {
      name: ROUTER_URL.auth.name,
      isPublic: true,
      title: ROUTER_URL.auth.title,
      requiresAuth: false,
    },
    children: [
      {
        path: ROUTER_URL.auth.children.login.link,
        name: ROUTER_URL.auth.children.login.name,
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/Auth/Login.vue"),
        meta: {
          isPublic: true,
          layout: "AuthLayout",
          title: ROUTER_URL.auth.children.login.title,
        },
      },
      {
        path: ROUTER_URL.auth.children.resetPassword.link,
        name: ROUTER_URL.auth.children.resetPassword.name,
        component: () =>
          import(
            /* webpackChunkName: "ResetPassword" */ "../views/Auth/ResetPassword.vue"
          ),
        meta: {
          isPublic: true,
          layout: "AuthLayout",
          title: ROUTER_URL.auth.children.resetPassword.title,
        },
      },
      {
        path: ROUTER_URL.auth.children.forgotPassword.link,
        name: ROUTER_URL.auth.children.forgotPassword.name,
        component: () =>
          import(
            /* webpackChunkName: "ForgotPassword" */ "../views/Auth/ForgotPassword.vue"
          ),
        meta: {
          isPublic: true,
          layout: "AuthLayout",
          title: ROUTER_URL.auth.children.forgotPassword.title,
        },
      },
    ],
  },
  {
    path: ROUTER_URL.adminPanel.link,
    name: ROUTER_URL.adminPanel.name,
    component: () =>
      import(
        /* webpackChunkName: "AdminLayout" */ "../layouts/Admin/AdminLayout.vue"
      ),
    meta: {
      name: ROUTER_URL.adminPanel.name,
      isPublic: false,
      title: ROUTER_URL.adminPanel.title,
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTER_URL.adminPanel.children.dashboard.link,
        name: ROUTER_URL.adminPanel.children.dashboard.name,
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../views/Dashboard/Dashboard.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.dashboard.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.districtManagement.link,
        name: ROUTER_URL.adminPanel.children.districtManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "DistrictManagement" */ "../views/DistrictManagement/DistrictManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.districtManagement.title,
        },
      },
      {
        path:ROUTER_URL.adminPanel.children.bulkUploadDistrict.link,
        name:ROUTER_URL.adminPanel.children.bulkUploadDistrict.name,
        component: () =>
          import(
        /* webpackChunkName: "DistrictManagement" */ "../views/BulkUpload/BulkUpload.vue"
         ),
         meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.bulkUploadDistrict.title,
        },
      },
      {
        path:ROUTER_URL.adminPanel.children.bulkUploadSchool.link,
        name:ROUTER_URL.adminPanel.children.bulkUploadSchool.name,
        component: () =>
          import(
        /* webpackChunkName: "SchoolManagement" */ "../views/BulkUpload/BulkUpload.vue"
         ),
         meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.bulkUploadSchool.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.schoolManagement.link,
        name: ROUTER_URL.adminPanel.children.schoolManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "SchoolManagement" */ "../views/SchoolManagement/SchoolManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.schoolManagement.title,
        },
      },
    
      {
        path: ROUTER_URL.adminPanel.children.campaignManagement.link,
        name: ROUTER_URL.adminPanel.children.campaignManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/CampaignManagement/CampaignManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.campaignManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.newCampaign.link,
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        component: () =>
      
          import(
            /* webpackChunkName: "CampaignManagement" */ "../views/CampaignManagement/NewCampaign/NewCampaign.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.newCampaign.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.teacherManagement.link,
        name: ROUTER_URL.adminPanel.children.teacherManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeacherManagement" */ "../views/TeacherManagement/TeacherManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.teacherManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.studentManagement.link,
        name: ROUTER_URL.adminPanel.children.studentManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "StudentManagement" */ "../views/StudentManagement/StudentManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.studentManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.needhelp.link,
        name: ROUTER_URL.adminPanel.children.needhelp.name,
        component: () =>
          import(
            /* webpackChunkName: "StudentManagement" */ "../views/NeedHelp/NeedHelp.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.needhelp.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.sponsorManagement.link,
        name: ROUTER_URL.adminPanel.children.sponsorManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "SponsorManagement" */ "../views/SponsorManagement/SponsorManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.sponsorManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.teamManagement.link,
        name: ROUTER_URL.adminPanel.children.teamManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/TeamManagement/TeamManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.teamManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.calendarManagement.link,
        name: ROUTER_URL.adminPanel.children.calendarManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/CalendarManagement/CalendarManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.calendarManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.listcalendarManagement.link,
        name: ROUTER_URL.adminPanel.children.listcalendarManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/CalendarManagement/ListCalendarManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.listcalendarManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.playerListCalendar.link,
        name: ROUTER_URL.adminPanel.children.playerListCalendar.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/CalendarManagement/PlayerListView/PlayerListView.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.playerListCalendar.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.resourceManagement.link,
        name: ROUTER_URL.adminPanel.children.resourceManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/ResourceCommon/ResourceCommon.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.resourceManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.onboardingManagement.link,
        name: ROUTER_URL.adminPanel.children.onboardingManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/OnboardingManagement/OnboardingManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.onboardingManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.resourceCommonUpload.link,
        name: ROUTER_URL.adminPanel.children.resourceCommonUpload.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/ResourceCommon/ResourceCommonUpload.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.resourceCommonUpload.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.gameresourceManagement.link,
        name: ROUTER_URL.adminPanel.children.gameresourceManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */  "../views/GameManagement/GameResource/GameResource.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.resourceManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.gameresourceUpload.link,
        name: ROUTER_URL.adminPanel.children.gameresourceUpload.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */  "../views/GameManagement/GameResource/GameResourceUpload.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.gameresourceUpload.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.nongameeventManagement.link,
        name: ROUTER_URL.adminPanel.children.nongameeventManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/NonGameEvent/NonGameEvent.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.nongameeventManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.hotelManagement.link,
        name: ROUTER_URL.adminPanel.children.hotelManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/HotelManagement/HotelManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.hotelManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.assemblyManagement.link,
        name: ROUTER_URL.adminPanel.children.assemblyManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/AssemblyManagement/AssemblyManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.assemblyManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.daycalendarManagement.link,
        name: ROUTER_URL.adminPanel.children.daycalendarManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/CalendarManagement/DayCalendarManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.daycalendarManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.weekcalendarManagement.link,
        name: ROUTER_URL.adminPanel.children.weekcalendarManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */ "../views/CalendarManagement/WeekCalendarManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.weekcalendarManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.donationManagement.link,
        name: ROUTER_URL.adminPanel.children.donationManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "DonationManagement" */ "../views/DonationManagement/DonationManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.donationManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.prizeManagement.link,
        name: ROUTER_URL.adminPanel.children.prizeManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "PrizeManagement" */ "../views/PrizeManagement/PrizeManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.prizeManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.emailSettings.link,
        name: ROUTER_URL.adminPanel.children.emailSettings.name,
        component: () =>
          import(
            /* webpackChunkName: "EmailSettings" */ "../views/EmailSettings/EmailSettings.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.emailSettings.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.automatedEmailForm.link,
        name: ROUTER_URL.adminPanel.children.automatedEmailForm.name,
        component: () =>
          import(
            /* webpackChunkName: "EmailSettings" */ "../views/EmailSettings/AutomateEmailTemplate/AutomatedEmailForm/AutomatedEmailForm.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.automatedEmailForm.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.manualEmailForm.link,
        name: ROUTER_URL.adminPanel.children.manualEmailForm.name,
        component: () =>
          import(
            /* webpackChunkName: "PrizeManagement" */ "../views/EmailSettings/ManualEmailTemplate/ManualTemplateForm/ManualTemplateForm.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.manualEmailForm.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.bulkEmailForm.link,
        name: ROUTER_URL.adminPanel.children.bulkEmailForm.name,
        component: () =>
          import(
            /* webpackChunkName: "emailSettings" */ "../views/EmailSettings/BulkEmails/BulkEmailForm/BulkEmailForm.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.bulkEmailForm.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.gameManagement.link,
        name: ROUTER_URL.adminPanel.children.gameManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "gameManagement" */ "../views/GameManagement/GameManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.gameManagement.title,
        },
      },
      {
        path: ROUTER_URL.adminPanel.children.gamecsvUpload.link,
        name: ROUTER_URL.adminPanel.children.gamecsvUpload.name,
        component: () =>
          import(
            /* webpackChunkName: "TeamManagement" */  "../views/GameManagement/GameResource/GamecsvUpload.vue"
          ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.adminPanel.children.gamecsvUpload.title,
        },
      },
    ],
  },
  {
    path: ROUTER_URL.schoolLandingPage.link,
    name: ROUTER_URL.schoolLandingPage.name,
    component: () =>
      import(
        /* webpackChunkName: "school landing page" */ "../views/SchoolLandingPage/SchoolLandingPage.vue"
      ),
    meta: {
      name: ROUTER_URL.schoolLandingPage.name,
      isPublic: false,
      title: ROUTER_URL.schoolLandingPage.title,
      requiresAuth: true,
    },
  },
  {
    path: ROUTER_URL.gamePanel.link,
    name: ROUTER_URL.gamePanel.name,
    component: () =>
      import(
        /* webpackChunkName: "school landing page" */ "../layouts/games/gameLayout.vue"
      ),
    meta: {
      name: ROUTER_URL.gamePanel.name,
      isPublic: false,
      title: ROUTER_URL.gamePanel.title,
      requiresAuth: true,
    },
    children:[
      {
        path: ROUTER_URL.gamePanel.children.dashboard.link,
        name: ROUTER_URL.gamePanel.children.dashboard.name,
        component: () =>
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/Dashboard/Dashboard.vue"
            ),
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.dashboard.title,
        },
      },
      {
        path: ROUTER_URL.gamePanel.children.gamecalendar.link,
        name: ROUTER_URL.gamePanel.children.gamecalendar.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/Gamecalendar/Gamecalendar.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gamecalendar.title,
        },
      },
      {
        path: ROUTER_URL.gamePanel.children.gamehostassembly.link,
        name: ROUTER_URL.gamePanel.children.gamehostassembly.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/TicketInfo/HostTicketInfo.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gamehostassembly.title,
        },
      },

      {
        path: ROUTER_URL.gamePanel.children.gamehostlist.link,
        name: ROUTER_URL.gamePanel.children.gamehostlist.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/GameHostAssembly/GameHostAssembly.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gamehostlist.title,
        },
      },
      
      {
        path: ROUTER_URL.gamePanel.children.gameresource.link,
        name: ROUTER_URL.gamePanel.children.gameresource.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/GameResource/GameResourcedashboard.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gameresource.title,
        },
      },
      {
        path: ROUTER_URL.gamePanel.children.gamecommittee.link,
        name: ROUTER_URL.gamePanel.children.gamecommittee.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/GameCommittee/GameCommittee.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gamecommittee.title,
        },
      },
      {
        path: ROUTER_URL.gamePanel.children.gameticketupdate.link,
        name: ROUTER_URL.gamePanel.children.gameticketupdate.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/TicketInfo/HostTicketInfo.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gameticketupdate.title,
        },
      },
      {
        path: ROUTER_URL.gamePanel.children.gameallassembly.link,
        name: ROUTER_URL.gamePanel.children.gameallassembly.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/GameManagement/GameDashboardVASH/AssemblyAllcheck.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gameallassembly.title,
        },
      },
      {
        path: ROUTER_URL.gamePanel.children.gameemail.link,
        name: ROUTER_URL.gamePanel.children.gameemail.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/EmailSettings/BulkEmails/Game/GamePanelEmail.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.gameemail.title,
        },
      },
      {
        path: ROUTER_URL.gamePanel.children.bulkGameEmailForm.link,
        name: ROUTER_URL.gamePanel.children.bulkGameEmailForm.name,
        component: () =>
      
          import(
            /* webpackChunkName: "Game-Dashboard" */ "../views/EmailSettings/BulkEmails/BulkEmailForm/BulkEmailGameForm.vue"
            ),
        
        meta: {
          isPublic: false,
          layout: "AdminDashboardLayout",
          title: ROUTER_URL.gamePanel.children.bulkGameEmailForm.title,
        },
      },
    ]
  },
  {
    path: ROUTER_URL.districtPanel.link,
    name: ROUTER_URL.districtPanel.name,
    component: () =>
      import(
        /* webpackChunkName: "CampaignLayout" */ "../layouts/Campaign/CampaignLayout.vue"
        ),
    meta: {
      name: ROUTER_URL.districtPanel.name,
      isPublic: false,
      title: ROUTER_URL.districtPanel.title,
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTER_URL.districtPanel.children.dashboard.link,
        name: ROUTER_URL.districtPanel.children.dashboard.name,
        component: () =>
          import(
            /* webpackChunkName: "District-Dashboard" */ "../views/Dashboard/CampaignPanel/Dashboard.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.dashboard.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.schoolManagement.link,
        name: ROUTER_URL.districtPanel.children.schoolManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-SchoolManagement" */ "../views/SchoolManagement/DistrictPanelSchool/DistrictPanelSchool"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.schoolManagement.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.teacherManagement.link,
        name: ROUTER_URL.districtPanel.children.teacherManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-TeacherManagement" */ "../views/TeacherManagement/TeacherManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.teacherManagement.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.studentManagement.link,
        name: ROUTER_URL.districtPanel.children.studentManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-StudentManagement" */ "../views/StudentManagement/StudentManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.studentManagement.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.needhelp.link,
        name: ROUTER_URL.districtPanel.children.needhelp.name,
        component: () =>
          import(
            /* webpackChunkName: "District-StudentManagement" */ "../views/NeedHelp/NeedHelp.vue"
          ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.needhelp.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.sponsorManagement.link,
        name: ROUTER_URL.districtPanel.children.sponsorManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-SponsorManagement" */ "../views/SponsorManagement/SponsorManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.sponsorManagement.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.donationManagement.link,
        name: ROUTER_URL.districtPanel.children.donationManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/DonationManagement/DonationManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.donationManagement.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.teamManagement.link,
        name: ROUTER_URL.districtPanel.children.teamManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/TeamManagement/TeamManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.teamManagement.title,
        },
      },
      {
        path: ROUTER_URL.districtPanel.children.emailSettings.link,
        name: ROUTER_URL.districtPanel.children.emailSettings.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/EmailSettings/BulkEmails/Campaign/CampaignPanelEmail.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.districtPanel.children.emailSettings.title, 
        },
      },
    ],
  },
  {
    path: ROUTER_URL.schoolPanel.link,
    name: ROUTER_URL.schoolPanel.name,
    component: () =>
      import(
        /* webpackChunkName: "CampaignLayout" */ "../layouts/Campaign/CampaignLayout.vue"
        ),
    meta: {
      name: ROUTER_URL.schoolPanel.name,
      isPublic: false,
      title: ROUTER_URL.schoolPanel.title,
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTER_URL.schoolPanel.children.dashboard.link,
        name: ROUTER_URL.schoolPanel.children.dashboard.name,
        component: () =>
          import(
            /* webpackChunkName: "District-Dashboard" */ "../views/Dashboard/CampaignPanel/Dashboard.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.dashboard.title,
        },
      },
      {
        path:ROUTER_URL.schoolPanel.children.bulkUploadTeacher.link,
        name:ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name,
        component: () =>
          import(
        /* webpackChunkName: "teacherManagement" */ "../views/BulkUpload/BulkUpload.vue"
         ),
         meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.bulkUploadTeacher.title,
        },
      },
      {
        path: ROUTER_URL.schoolPanel.children.teacherManagement.link,
        name: ROUTER_URL.schoolPanel.children.teacherManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-TeacherManagement" */ "../views/TeacherManagement/TeacherManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.teacherManagement.title,
        },
      },
      {
        path: ROUTER_URL.schoolPanel.children.studentManagement.link,
        name: ROUTER_URL.schoolPanel.children.studentManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-StudentManagement" */ "../views/StudentManagement/StudentManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.studentManagement.title,
        },
      },
      {
        path: ROUTER_URL.schoolPanel.children.needhelp.link,
        name: ROUTER_URL.schoolPanel.children.needhelp.name,
        component: () =>
          import(
            /* webpackChunkName: "District-StudentManagement" */ "../views/NeedHelp/NeedHelp.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.needhelp.title,
        },
      },
      {
        path: ROUTER_URL.schoolPanel.children.donationManagement.link,
        name: ROUTER_URL.schoolPanel.children.donationManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/DonationManagement/DonationManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.donationManagement.title,
        },
      },
      {
        path: ROUTER_URL.schoolPanel.children.sponsorManagement.link,
        name: ROUTER_URL.schoolPanel.children.sponsorManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-SponsorManagement" */ "../views/SponsorManagement/SponsorManagement.vue"
          ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.sponsorManagement.title,
        },
      },
      {
        path: ROUTER_URL.schoolPanel.children.teamManagement.link,
        name: ROUTER_URL.schoolPanel.children.teamManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/TeamManagement/TeamManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.teamManagement.title,
        },
        
      },
      {
        path: ROUTER_URL.schoolPanel.children.galleryManagement.link,
        name: ROUTER_URL.schoolPanel.children.galleryManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "School-galleryManagement" */ "../views/GalleryManagement/GalleryManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.galleryManagement.title,
        },  
      },
      {
        path: ROUTER_URL.schoolPanel.children.emailSettings.link,
        name: ROUTER_URL.schoolPanel.children.emailSettings.name,
        component: () =>
          import(
            /* webpackChunkName: "School-emailSettings" */ "../views/EmailSettings/BulkEmails/Campaign/CampaignPanelEmail.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.emailSettings.title,
        },  
      },
      {
        path: ROUTER_URL.schoolPanel.children.todolist.link,
        name: ROUTER_URL.schoolPanel.children.todolist.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/CampaignManagement/ToDoList/Todolist.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.todolist.title,
        },
        
      },
      {
        path: ROUTER_URL.schoolPanel.children.hosttodolist.link,
        name: ROUTER_URL.schoolPanel.children.hosttodolist.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/CampaignManagement/ToDoList/Reportpdfhost.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.hosttodolist.title,
        },
        
      },
      {
        path: ROUTER_URL.schoolPanel.children.campaigncalendar.link,
        name: ROUTER_URL.schoolPanel.children.campaigncalendar.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/CampaignManagement/CampaignCalendar/CampaignCalendar.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.campaigncalendar.title,
        },
        
      },
      {
        path: ROUTER_URL.schoolPanel.children.resource.link,
        name: ROUTER_URL.schoolPanel.children.resource.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/CampaignManagement/Resource/Resource.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.resource.title,
        },
        
      },
      {
        path: ROUTER_URL.schoolPanel.children.resourceupload.link,
        name: ROUTER_URL.schoolPanel.children.resourceupload.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/CampaignManagement/Resource/Resourceupload.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.resourceupload.title,
        },
        
      },
      {
        path: ROUTER_URL.schoolPanel.children.campaignGame.link,
        name: ROUTER_URL.schoolPanel.children.campaignGame.name,
        component: () =>
          import(
            /* webpackChunkName: "District-DonationManagement" */ "../views/GameManagement/GameTable/GameTable.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.schoolPanel.children.campaignGame.title,
        },
        
      },
    ],
  },
  {
    path: ROUTER_URL.teacherPanel.link,
    name: ROUTER_URL.teacherPanel.name,
    component: () =>
      import(
        /* webpackChunkName: "CampaignLayout" */ "../layouts/Campaign/CampaignLayout.vue"
        ),
    meta: {
      name: ROUTER_URL.teacherPanel.name,
      isPublic: false,
      title: ROUTER_URL.teacherPanel.title,
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTER_URL.teacherPanel.children.dashboard.link,
        name: ROUTER_URL.teacherPanel.children.dashboard.name,
        component: () =>
          import(
            /* webpackChunkName: "District-Dashboard" */ "../views/Dashboard/CampaignPanel/Dashboard.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.teacherPanel.children.dashboard.title,
        },
      },
      {
        path: ROUTER_URL.teacherPanel.children.studentManagement.link,
        name: ROUTER_URL.teacherPanel.children.studentManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "District-StudentManagement" */ "../views/StudentManagement/StudentManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.teacherPanel.children.studentManagement.title,
        },
      },
      {
        path: ROUTER_URL.teacherPanel.children.needhelp.link,
        name: ROUTER_URL.teacherPanel.children.needhelp.name,
        component: () =>
          import(
            /* webpackChunkName: "District-StudentManagement" */ "../views/NeedHelp/NeedHelp.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.teacherPanel.children.needhelp.title,
        },
      },
      {
        path: ROUTER_URL.teacherPanel.children.galleryManagement.link,
        name: ROUTER_URL.teacherPanel.children.galleryManagement.name,
        component: () =>
          import(
            /* webpackChunkName:"Teacher-galleryManagement" */ "../views/GalleryManagement/GalleryManagement.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.teacherPanel.children.galleryManagement.title,
        },
      },
    ],
  },
  {
    path: ROUTER_URL.peteacherPanel.link,
    name: ROUTER_URL.peteacherPanel.name,
    component: () =>
      import(
        /* webpackChunkName: "CampaignLayout" */ "../layouts/Campaign/CampaignLayout.vue"
        ),
    meta: {
      name: ROUTER_URL.peteacherPanel.name,
      isPublic: false,
      title: ROUTER_URL.peteacherPanel.title,
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTER_URL.peteacherPanel.children.dashboard.link,
        name: ROUTER_URL.peteacherPanel.children.dashboard.name,
        component: () =>
          import(
            /* webpackChunkName: "District-Dashboard" */ "../views/Dashboard/PeTeacherDashboard/Dashboard.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.peteacherPanel.children.dashboard.title,
        },
      },
    ]
  },
  {
    path: ROUTER_URL.principalPanel.link,
    name: ROUTER_URL.principalPanel.name,
    component: () =>
      import(
        /* webpackChunkName: "CampaignLayout" */ "../layouts/Campaign/CampaignLayout.vue"
        ),
    meta: {
      name: ROUTER_URL.principalPanel.name,
      isPublic: false,
      title: ROUTER_URL.principalPanel.title,
      requiresAuth: true,
    },
    children: [
      {
        path: ROUTER_URL.principalPanel.children.dashboard.link,
        name: ROUTER_URL.principalPanel.children.dashboard.name,
        component: () =>
          import(
            /* webpackChunkName: "District-Dashboard" */ "../views/Dashboard/PeTeacherDashboard/Dashboard.vue"
            ),
        meta: {
          isPublic: false,
          layout: "CampaignDashboardLayout",
          title: ROUTER_URL.principalPanel.children.dashboard.title,
        },
      },
    ]
  }
];
export default routes;
